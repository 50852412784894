<template>
  <v-card class="mx-6" outlined>
    <v-radio-group v-model="harmonic" row>
      <v-radio v-for="n in 5" :key="n" :label="`${n}oct.`" :value="n"></v-radio>
    </v-radio-group>

    <v-col class="mb=4">
      <v-dialog v-model="dialog" width="800">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on">
            音階生成
          </v-btn>
        </template>

        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            音階生成
          </v-card-title>
          <v-card-actions>
            <v-col cols="2">
              <v-row class="my-2" v-for="(tone, index) in tonesEs" :key="index">
                <v-btn
                  small
                  @click="
                    tonic = tone.value;
                    disTonic = tone.text;
                  "
                >
                  {{ tone.text }}
                </v-btn>
              </v-row>
            </v-col>

            <v-col cols="2">
              <v-row class="my-2" v-for="(tone, index) in tones" :key="index">
                <v-btn
                  small
                  @click="
                    tonic = tone.value;
                    disTonic = tone.text;
                  "
                >
                  {{ tone.text }}
                </v-btn>
              </v-row>
            </v-col>

            <v-col cols="2">
              <v-row class="my-2" v-for="(tone, index) in tonesIs" :key="index">
                <v-btn
                  small
                  @click="
                    tonic = tone.value;
                    disTonic = tone.text;
                  "
                >
                  {{ tone.text }}
                </v-btn>
              </v-row>
            </v-col>

            <v-col>
              <v-row class="ma-0">
                <v-radio-group v-model="key" row>
                  <v-radio label="長音階" value="major"></v-radio>
                  <v-radio
                    :disabled="makeDoubleStopScaleflg"
                    label="和声短音階"
                    value="harmonicMinor"
                  ></v-radio>
                  <v-radio
                    :disabled="makeDoubleStopScaleflg"
                    label="旋律短音階"
                    value="melodicMinor"
                  ></v-radio>
                </v-radio-group>
              </v-row>
              <v-row class="ma-0">
                <v-radio-group v-model="oct" row>
                  <v-radio
                    v-for="n in 5"
                    :key="n"
                    :label="`${n}oct.`"
                    :value="n"
                  ></v-radio>
                </v-radio-group>
              </v-row>
              <v-row class="ma-0">
                <v-card outlined>
                  <v-card-text class="pa-2">
                    {{ disTonic }} {{ key }} {{ oct }}オクターブ
                  </v-card-text>
                </v-card>
              </v-row>
              <v-row class="ma-0 pt-2">
                <v-checkbox
                  class="mr-4"
                  v-model="makeDoubleStopScaleflg"
                  label="重音"
                  disabled
                />
                <v-checkbox
                  class="mr-4"
                  v-model="dsreverse"
                  label="reverse"
                  disabled
                />
                <v-select
                  class="mt-3"
                  v-model="dsintn"
                  dense
                  :items="dsScale"
                  label="重音スケール"
                  outlined
                  :disabled="!makeDoubleStopScaleflg"
                ></v-select>
              </v-row>
              <v-row class="ma-0" align-center>
                <v-btn
                  @click="
                    dialog = false;
                    makeScale(tonic, key, oct);
                  "
                >
                  音階生成
                </v-btn>
                <v-btn
                  @click="
                    dialog = false;
                    makeArpeggio(tonic, key, oct);
                  "
                  :disabled="makeDoubleStopScaleflg"
                >
                  アルペジオ生成
                </v-btn>
              </v-row>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>

    <v-row>
      <v-col align-self="center">
        <v-select
          v-model="intn"
          dense
          :items="intnation"
          label="intonation"
          class="mt-4"
          outlined
        ></v-select>
      </v-col>
      <v-col align-self="center">
        <v-checkbox v-model="reverse" label="reverse" />
      </v-col>
    </v-row>

    <v-card class="mb-6">
      <v-row class="ml-6">
        <v-col class="mr-3" cols="2 ">
          <v-row class="my-2" v-for="(tone, index) in tonesEsEs" :key="index">
            <v-btn small @click="register(tone.value)">
              {{ tone.text }}
            </v-btn>
          </v-row>
        </v-col>

        <v-col cols="2">
          <v-row class="my-2" v-for="(tone, index) in tonesEs" :key="index">
            <v-btn small @click="register(tone.value)">
              {{ tone.text }}
            </v-btn>
          </v-row>
        </v-col>

        <v-col cols="2">
          <v-row class="my-2" v-for="(tone, index) in tones" :key="index">
            <v-btn small @click="register(tone.value)">
              {{ tone.text }}
            </v-btn>
          </v-row>
        </v-col>

        <v-col cols="2">
          <v-row class="my-2" v-for="(tone, index) in tonesIs" :key="index">
            <v-btn small @click="register(tone.value)">
              {{ tone.text }}
            </v-btn>
          </v-row>
        </v-col>

        <v-col cols="2">
          <v-row class="my-2" v-for="(tone, index) in tonesIsIs" :key="index">
            <v-btn small @click="register(tone.value)">
              {{ tone.text }}
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-card>

    <v-card class="mb-3" outlined>
      {{ inputToneDisplay }}
    </v-card>

    <v-row>
      <v-col align-self="center">
        <v-btn small @click="remove">Remove</v-btn>
        <v-btn small @click="clear">Clear</v-btn>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-btn small @click="modinputTones">double-stop</v-btn>
      </v-col>
      <v-col>
        <v-checkbox v-model="separate" label="separate" />
      </v-col>
      <v-col>
        <v-checkbox v-model="repeat" label="repeat" />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col>
        <v-text-field outlined label="tempo" v-model="tempo" dense />
      </v-col>
      <v-col>
        <v-btn small @click="osc">Play</v-btn>
        <v-btn small @click="stop">stop</v-btn>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-text-field
          outlined
          clearable
          label="Build"
          v-model="preArray"
          dense
        />
      </v-col>
      <v-col class="mb-4">
        <v-btn small @click="buildArray">Insert</v-btn>
      </v-col>
    </v-row>
    <v-card>
      <v-card-text>
        【説明】<br />
        バイオリン練習用に作成した理論値確認アプリβ版です。<br />
        1oct.のGがG線開放弦です。上記のラジオボタンで音の高さが変更できます。<br />
        ピタゴラス音律はメロディを弾く際に使用される音程です。<br />
        ○度を選び、下記リストの音を選択すると、選択した音に対する上方向に○度の音が鳴ります。(例：Gを押すとHが鳴る。)<br />
        reverseにチェックを入れると下方向に○度の音が鳴ります。(例：Gを押すとEが鳴る。)<br />
        REMOVE:入力した音を1音取り除きます。<br />
        CREAR：入力した音を全て消去します。<br />
        DOUBLE-STOP：入力した音の1,2番目、3,4番目…をペア扱いで重音にする音リストを作ります。<br />
        separateにチェックを入れてDOUBLE-STOPを押すと単音→単音→重音の順で鳴る音リストを作ります。<br />
        tempo：テンポを変更できます。<br />
        PLAY：インプットした音を演奏します。<br />
        Build:Hzを入力し、「INSERT」ボタンを押すと鳴らす音リストに入ります。<br /><br />
        【言い訳】<br />
        JavaScript&AWS勉強用に作成した理論値生成アプリです。<br />
        気付いたら使えなくなっているかもしれませんが、テスト運用のためご了承いただけますと幸いです。<br />
        繊細なので優しく扱ってください。。。エラー処理は書いていません。<br />
        バグった時はページをそっとリロードしてください。<br />
        現在のコードは大変汚いので突っ込みを入れないでください。。。今後リファクタリング予定です。<br />
        改善案・バグ報告大歓迎です。何かあればTwitterアカウントまでご連絡ください。
        <v-btn icon href="https://twitter.com/vn39_"
          ><v-icon>mdi-twitter</v-icon></v-btn
        >
        <br /><br />
        【今後の目標】<br />
        音の長さを変えられるようにする。<br />
        音の強弱を付けられるようにする。<br />
        見た目をもっと格好良くする。<br />
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";

function wait(sec) {
  return new Promise(resolve => {
    setTimeout(resolve, sec * 1000);
  });
}

@Component
export default class Tones extends Vue {
  isPlaying = false;
  oscillator = null;
  inputTones = [];
  inputDsTones = [];
  harmonic = 1;
  repeat = false;
  reverse = false;
  dsreverse = false;
  separate = false;
  oscillators = [];
  intn = "pythagoras";
  dsintn = null;
  preArray = "";
  tempo = 60;
  ds = false;
  ctx = null;
  oct = 1;
  key = "major";
  tonic = 261.9055024;
  disTonic = "C";
  dialog = false;
  stopflg = false;
  makeDoubleStopScaleflg = false;

  tonesIs = [
    { text: "G♯", value: 209.77734375 },
    { text: "A♯", value: 235.99951171875 },
    { text: "H♯", value: 265.499450683593 },
    { text: "C♯", value: 279.703125 },
    { text: "D♯", value: 314.666015625 },
    { text: "E♯", value: 353.999267578125 },
    { text: "F♯", value: 372.9375 }
  ];

  tonesIsIs = [
    { text: "G♯♯", value: 224.015161514282 },
    { text: "A♯♯", value: 252.017056703567 },
    { text: "H♯♯", value: 283.519188791513 },
    { text: "C♯♯", value: 298.686882019042 },
    { text: "D♯♯", value: 336.022742271423 },
    { text: "E♯♯", value: 378.025585055351 },
    { text: "F♯♯", value: 199.124588012695 }
  ];

  tones = [
    { text: "G", value: 196.444444444444 },
    { text: "A", value: 221},
    { text: "H", value: 248.625 },
    { text: "C", value: 261.925925925926 },
    { text: "D", value: 294.666666666667 },
    { text: "E", value: 331.5 },
    { text: "F", value: 349.234567901235 }
  ];

  tonesEs = [
    { text: "G♭", value: 367.917898694305 },
    { text: "A♭", value: 206.953818015546 },
    { text: "H♭", value: 232.82304526749 },
    { text: "C♭", value: 245.278599129536 },
    { text: "D♭", value: 275.938424020729 },
    { text: "E♭", value: 310.43072702332 },
    { text: "F♭", value: 327.038132172715 }
  ];

  tonesEsEs = [
    { text: "G♭♭", value: 344.533999325988 },
    { text: "A♭♭", value: 387.600749241737},
    { text: "H♭♭", value: 218.025421448477 },
    { text: "C♭♭", value: 229.689332883992 },
    { text: "D♭♭", value: 258.400499494491 },
    { text: "E♭♭", value: 290.700561931303 },
    { text: "F♭♭", value:  306.252443845323}
  ];

  intnation = [
    { text: "ピタゴラス音律", value: "pythagoras" },
    { text: "長2度(大)", value: 9 / 8 },
    { text: "長2度(小)", value: 10 / 9 },
    { text: "長3度", value: 5 / 4 },
    { text: "短3度", value: 6 / 5 },
    { text: "完全4度", value: 4 / 3 },
    { text: "完全5度", value: 3 / 2 },
    { text: "短6度", value: 8 / 5 },
    { text: "長6度", value: 5 / 3 },
    { text: "短7度", value: 16 / 9 },
    { text: "完全8度", value: 2 / 1 }
  ];
  //value二つもちたい。
  dsScale = [
    { text: "3度", major: 5 / 4, minor: 6 / 5 },
    { text: "4度", major: 4 / 3, minor: 4 / 3 },
    { text: "5度", major: 3 / 2, minor: 3 / 2 },
    { text: "6度", major: 5 / 3, minor: 8 / 5 },
    { text: "オクターブ", major: 2 / 1, minor: 2 / 1 },
    { text: "10度", major: ((2 / 1) * 5) / 4, minor: ((2 / 1) * 6) / 5 }
  ];

  get inputToneDisplay() {
    return this.inputTones.length ? this.inputTones : "No Data";
  }

  addTone(tone) {
    this.inputTones.push(tone);
  }

  async playTone(tone) {
    const ctx = new AudioContext();
    const oscillator = ctx.createOscillator();
    oscillator.type = "sine"; // sine, square, sawtooth, triangleがある
    oscillator.frequency.setValueAtTime(tone, ctx.currentTime); // 440HzはA4(4番目のラ)
    oscillator.connect(ctx.destination);
    oscillator.start();
    await wait(0.5);
    oscillator?.stop();
    await ctx.close();
  }

  register(tonePre) {
    const tone = tonePre * 2 ** (this.harmonic - 1);
    if (this.intn == "pythagoras") {
      this.playTone(tone);
      this.addTone(tone);
    } else {
      this.calcIntn(tone);
    }
  }

  async play(iTones) {
    const oscillators = iTones.map(i => {
      if (Array.isArray(i)) {
        return i.map(j => this.makeOsc(j));
      } else {
        return this.makeOsc(i);
      }
    });
    this.oscillators = oscillators;
  }

  makeOsc(tone) {
    this.ctx = new AudioContext();
    const oscillator = this.ctx.createOscillator();
    oscillator.type = "sine"; // sine, square, sawtooth, triangleがある
    oscillator.frequency.setValueAtTime(tone, this.ctx.currentTime); // 440HzはA4(4番目のラ)
    oscillator.connect(this.ctx.destination);
    return oscillator;
  }

  modinputTones() {
    let i = 0;
    const modTones = [];
    let tmpTones = [];

    for (const tone of this.inputTones) {
      if (this.separate) {
        modTones.push(tone);
      }
      tmpTones.push(tone);
      i = i + 1;
      if (i == 2) {
        modTones.push(tmpTones);
        tmpTones = [];
        i = 0;
      }
    }
    this.inputTones = modTones;
  }

  async osc() {
    this.play(this.inputTones);
    for (const osc of this.oscillators) {
      if (this.stopflg) {
        await wait(1.5);
        this.ddddddoscillators = [];
        this.stopflg = false;
        this.repeat = false;
        break;
      }
      if (Array.isArray(osc)) {
        osc[0].start();
        osc[1].start();
        await wait((1 * 60) / this.tempo - 0.05);
        await wait(0.05);
        osc.forEach(e => e?.stop());
      } else {
        osc.start();
        await wait((1 * 60) / this.tempo - 0.05);
        await wait(0.05);
        osc?.stop();
      }
    }
    if (this.repeat) {
      this.ctx.close();
      this.oscillators = [];
      await this.osc();
    }
    this.oscillators = [];
    this.ctx.close();
  }

  remove() {
    this.inputTones.splice(-1);
  }

  clear() {
    this.oscillators.forEach(e => e?.stop());
    this.isPlaying = false;
    this.inputTones = [];
    this.oscillators = [];
  }
  stop() {
    this.stopflg = true;
  }

  calcIntn(tone) {
    let intnTmp = this.intn;
    if (this.reverse) {
      intnTmp = 1 / this.intn;
    }

    const calcTone = tone * intnTmp;
    this.playTone(calcTone);
    this.inputTones.push(calcTone);
  }

  buildArray() {
    let preArray = this.preArray.replace("[", "");
    preArray = preArray.replace("]", "");
    preArray = preArray.replace(" ", "");
    preArray = preArray.split(",");
    preArray.forEach(e => this.inputTones.push(Number(e)));
    this.preArray = "";
  }

  makeScale(tonic, key, oct) {
    const conTonic = tonic;
    if (key == "major") {
      for (let i = 1; i <= oct; i++) {
        this.inputTones.push(tonic);
        this.inputTones.push((tonic * 9) / 8);
        this.inputTones.push((tonic * 81) / 64);
        this.inputTones.push((tonic * 4) / 3);
        this.inputTones.push((tonic * 3) / 2);
        this.inputTones.push((tonic * 27) / 16);
        this.inputTones.push((tonic * 243) / 128);
        tonic = conTonic * 2 ** i;

        tonic = conTonic * 2 ** i;
      }
      this.inputTones.push(conTonic * 2 ** oct);
      this.inputTones = this.inputTones.concat([...this.inputTones].reverse());
    } else if (key == "harmonicMinor") {
      for (let i = 1; i <= oct; i++) {
        this.inputTones.push(tonic);
        this.inputTones.push((tonic * 9) / 8);
        this.inputTones.push((((tonic * 9) / 8) * 256) / 243);
        this.inputTones.push((((((tonic * 9) / 8) * 256) / 243) * 9) / 8);
        this.inputTones.push(
          (((((((tonic * 9) / 8) * 256) / 243) * 9) / 8) * 9) / 8
        );
        this.inputTones.push(
          (((((((((tonic * 9) / 8) * 256) / 243) * 9) / 8) * 9) / 8) * 256) /
            243
        );
        this.inputTones.push(
          (((((((((((((tonic * 9) / 8) * 256) / 243) * 9) / 8) * 9) / 8) *
            256) /
            243) *
            9) /
            8) *
            256) /
            243
        );
        tonic = conTonic * 2 ** i;
      }

      this.inputTones.push(conTonic * 2 ** oct);
      this.inputTones = this.inputTones.concat([...this.inputTones].reverse());
    } else {
      for (let i = 1; i <= oct; i++) {
        this.inputTones.push(tonic);
        this.inputTones.push((tonic * 9) / 8);
        this.inputTones.push((((tonic * 9) / 8) * 256) / 243);
        this.inputTones.push((((((tonic * 9) / 8) * 256) / 243) * 9) / 8);
        this.inputTones.push(
          (((((((tonic * 9) / 8) * 256) / 243) * 9) / 8) * 9) / 8
        );
        this.inputTones.push(
          (((((((((tonic * 9) / 8) * 256) / 243) * 9) / 8) * 9) / 8) * 9) / 8
        );
        this.inputTones.push(
          (((((((((((tonic * 9) / 8) * 256) / 243) * 9) / 8) * 9) / 8) * 9) /
            8) *
            9) /
            8
        );
        tonic = conTonic * 2 ** i;
      }
      this.inputTones.push(conTonic * 2 ** oct);
      for (let i = oct; i >= 1; i--) {
        tonic = conTonic * 2 ** i;
        this.inputTones.push(tonic);
        this.inputTones.push((tonic * 8) / 9);
        this.inputTones.push((((tonic * 8) / 9) * 8) / 9);
        this.inputTones.push((((((tonic * 8) / 9) * 8) / 9) * 243) / 256);
        this.inputTones.push(
          (((((((tonic * 8) / 9) * 8) / 9) * 243) / 256) * 8) / 9
        );
        this.inputTones.push(
          (((((((((tonic * 8) / 9) * 8) / 9) * 243) / 256) * 8) / 9) * 8) / 9
        );
        this.inputTones.push(
          (((((((((((tonic * 8) / 9) * 8) / 9) * 243) / 256) * 8) / 9) * 8) /
            9) *
            243) /
            256
        );
      }
      this.inputTones.push(conTonic);
    }
    if (this.makeDoubleStopScaleflg) {
      this.makeDoubleStopScale();
    }
  }

  makeArpeggio(tonic, key, oct) {
    const conTonic = tonic;
    if (key == "major") {
      for (let i = 1; i <= oct; i++) {
        this.inputTones.push(tonic);
        this.inputTones.push((tonic * 5) / 4);
        this.inputTones.push((tonic * 3) / 2);
        tonic = conTonic * 2 ** i;
      }
      const rev = [...this.inputTones].reverse();
      this.inputTones.push(tonic);
      this.inputTones = [...this.inputTones].concat([...rev]);
      tonic = conTonic;

      const tmp = [];
      for (let i = 1; i <= oct; i++) {
        tmp.push((tonic * 4) / 3);
        tmp.push((tonic * 5) / 3);
        tonic = conTonic * 2 ** i;
        tmp.push(tonic);
      }
      this.inputTones = this.inputTones.concat([...tmp]);
      this.inputTones.splice(-1);
      this.inputTones = this.inputTones.concat([...tmp].reverse());
      this.inputTones.push(conTonic);
    } else {
      for (let i = 1; i <= oct; i++) {
        this.inputTones.push(tonic);
        this.inputTones.push((tonic * 6) / 5);
        this.inputTones.push((tonic * 3) / 2);
        tonic = conTonic * 2 ** i;
      }
      const rev = [...this.inputTones].reverse();
      this.inputTones.push(tonic);
      this.inputTones = [...this.inputTones].concat([...rev]);
      tonic = conTonic;

      const tmp = [];
      for (let i = 1; i <= oct; i++) {
        tmp.push((tonic * 4) / 3);
        tmp.push((tonic * 8) / 5);
        tonic = conTonic * 2 ** i;
        tmp.push(tonic);
      }
      this.inputTones = this.inputTones.concat([...tmp]);
      this.inputTones.splice(-1);
      this.inputTones = this.inputTones.concat([...tmp].reverse());
      this.inputTones.push(conTonic);
    }
  }

  makeDoubleStopScale() {
    const tmp = [];
    const dsScale = this.inputTones.map(e => e * this.dsintn);
    for (let i = 0; i < this.inputTones.length; i++) {
      tmp.push(this.inputTones[i]);
      tmp.push(dsScale[i]);
    }
    this.inputTones = tmp;
  }

  mounted() {
    window.AudioContext = window.AudioContext || window.webkitAudioContext;
  }
}
</script>

<style scoped>
.buttons {
  margin-top: 35px;
}
.ghost {
  opacity: 0.5;
  background: ♯c8ebfb;
}
</style>
