import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Calendar from "@/views/calendar/calendar.vue";
import Money from "@/views/money/money.vue";
import Recipe from "@/views/recipe/recipe.vue";
import Diary from "@/views/diary/diary.vue";
import Todo from "@/views/todo/todo.vue";
import Tones from "@/views/tones/tones.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  { path: "/", component: Tones },
  //  { path: "/calendar", component: Calendar },
  //  { path: "/money", component: Money },
  //  { path: "/recipe", component: Recipe },
  //  { path: "/diary", component: Diary },
  //  { path: "/todo", component: Todo },
  { path: "/tones", component: Tones }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
