





























import { Component, Vue } from "vue-property-decorator";
import lodash from "lodash";

@Component
export default class AppHeader extends Vue {
  drawer = false;
  title = "Tones";

  setTitle(title: string) {
    this.title = lodash.capitalize(title);
    this.$router.push(`/${title}`);
  }
}
